module.exports = {
    "comp-sci-bs": {
        "title": "Bachelor's in Computer Science Program Guide",
        "thumbnail": "/assets/images/thumbnails/comp-sci-bs.jpg",
        "document": "/assets/docs/Bachelors in Computer Science.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, how to save time and money with **transfer credits**, and your next steps."
    },
    "comp-sci-ms": {
        "title": "Master's in Computer Science Program Guide",
        "thumbnail": "/assets/images/thumbnails/comp-sci-ms.jpg",
        "document": "/assets/docs/Masters in Computer Science.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, how to save time and money with **transfer credits**, and your next steps."
    },
    "mba": {
        "title": "Master of Business Administration",
        "thumbnail": "/assets/images/thumbnails/mba.jpg",
        "document": "/assets/docs/Master of Business Administration.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, how to save time and money with **transfer credits**, and your next steps."
    },
    "psychology-ba": {
        "title": "Bachelor's in Computer Science Program Guide",
        "thumbnail": "/assets/images/thumbnails/psychology-ba.jpg",
        "document": "/assets/docs/Bachelors in Psychology.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, how to save time and money with **transfer credits**, and your next steps."
    }
}